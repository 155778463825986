export async function listen(name: string, languageCode: string) {
    console.log(`Listening to ${name} in language ${languageCode}`);
  
    if (!window.speechSynthesis) {
      throw new Error('Speech Synthesis not available');
    }
  
    const synthesis: SpeechSynthesis = window.speechSynthesis;
  
    const voices = await new Promise<SpeechSynthesisVoice[]>((resolve) =>
      setTimeout(() => {
        const voices = synthesis.getVoices();
        resolve(voices);
      }, 500)
    );
  
    let voice = voices.filter((v) => v.lang.toLowerCase() === languageCode.toLowerCase()).pop();
  
    if (!voice) {
      voice = voices
        .filter((v) => {
          return v.lang.split('-')[0] === languageCode.split('-')[0];
        })
        .pop();
      if (!voice) {
        alert('Sorry! No voices available in your browser');
        throw new Error('No voice available');
      }
    }
  
    const listenThis = new SpeechSynthesisUtterance(name);
    listenThis.voice = voice;
    listenThis.rate = 0.8;
  
    synthesis.speak(listenThis);
  }