import styled from 'styled-components';

export const DefaultButton = styled.div<DefaultButtonProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  border-radius: var(--borderRadius);
  box-shadow: 4px 6px 0 rgba(21, 21, 21, 0.82);
  height: 64px;

  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--black);

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  user-select: none;

  :focus {
    outline: none !important;
  }
  outline: none;
`;

export type DefaultButtonProps = {
  children?: any;
  onClick: (e?) => void;
  icon?: string;
  width?: string;
  iconPosition?: 'before' | 'after';
};
