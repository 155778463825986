const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://api.saymyname.io';

export class SpeakRecorder {
  private mediaStream: MediaStream;
  private mediaRecorder: MediaRecorder;
  private chunks: any[];
  private file: File;

  async init() {
    await this.requestMediaDevices();
    this.mediaRecorder = new MediaRecorder(this.mediaStream);

    this.mediaRecorder.ondataavailable = (event) => {
      this.chunks.push(event.data);
    };
  }

  private async requestMediaDevices() {
    const audioCtx = new AudioContext();
    if (navigator.mediaDevices) {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (e) {
        return;
      }
      audioCtx.createMediaStreamSource(this.mediaStream);
    }
  }

  async start() {
    this.chunks = [];
    this.mediaRecorder.start();
  }

  async stop(recordingId: string, name: string, languageCode: string) {
    const onRecordingStopped = new Promise((res) => {
      this.mediaRecorder.onstop = () => {
        res(true);
      };
    });

    this.mediaRecorder.stop();

    await onRecordingStopped;

    const formData = new FormData();
    const encoding = 'audio/mpeg';
    const blob = new Blob(this.chunks, { type: encoding });
    this.file = new File([blob], `${recordingId}.mp3`);

    formData.append('audio', this.file);
    formData.append('name', name);
    formData.append('encoding', encoding);
    formData.append('languageCode', languageCode);

    try {
      const recordResponse = await fetch(`${API_URL}/record/${recordingId}`, {
        method: 'POST',
        body: formData,
      });

      if (recordResponse.status !== 200) {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      throw new Error('Not possible to recognise');
    }
  }
}
