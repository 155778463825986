const translationMap = {
  en: {
    'header.pronounce': 'Pronounce',
    'header.footballers': 'Footballers',
    'header.nameRight': 'Names Right',
    'system.connecting': 'Connecting to the server',
    'selector.allCountries': 'All countries',
    'speak.title': 'PRACTICE',
    'speak.subtitle': 'Tap/click to practice',
    'speakNow.title': 'SPEAK NOW',
    'speakNow.subtitle': 'Tap/click to stop',
    'listen.title': 'LISTEN',
    'listen.subtitle': 'Learn by listening',
    'alt.listen': 'Listen',
    'alt.speak': 'Speak now',
    'footer.whyThisMatters': 'Why this matters?',
    'footer.contactUs': 'Contact us',
    'controller.next': 'Next',
    'results.stayTuned': 'Stay tuned',
    'results.processing': 'Processing...',
    'results.sorry': 'Sorry!',
    'results.best': 'Good job!',
    'results.alright': 'Alright',
    'results.ok': "It's ok!",
    'results.incorrect': 'Not quite right!',
    'results.subtitle.stayTuned': 'not supported yet.',
    'results.subtitle.processing': 'Please wait a few seconds.',
    'results.subtitle.preProcessing': "Player's name language",
    'results.subtitle.sorry': "We couldn't recognise it",
    'results.subtitle.best': 'Sounds really good!',
    'results.subtitle.alright': 'Recognisable, practice more!',
    'results.subtitle.ok': 'Try listening again',
    'results.subtitle.incorrect': 'Try listening again.',
    'share.copied': 'Copied',
    'options.share': 'Share',
    'options.showFootballers': 'Show footballers from',
  },
  pt: {
    'header.pronounce': 'Fale o nome',
    'header.footballers': 'dos craques',
    'header.nameRight': 'corretamente',
    'system.connecting': 'Conectando ao servidor',
    'selector.allCountries': 'Todos os países',
    'speak.title': 'PRATICAR',
    'speak.subtitle': 'Clique para treinar',
    'speakNow.title': 'FALE AGORA',
    'speakNow.subtitle': 'Clique para parar',
    'listen.title': 'OUVIR',
    'listen.subtitle': 'Clique para ouvir',
    'alt.listen': 'Escutar',
    'alt.speak': 'Fale agora',
    'footer.whyThisMatters': 'Por que isso importa?',
    'footer.contactUs': 'Entre em contato',
    'controller.next': 'Próximo',
    'results.stayTuned': 'Fique ligado',
    'results.processing': 'Processando...',
    'results.sorry': 'Desculpe!',
    'results.best': 'Ótimo!',
    'results.alright': 'Está bom',
    'results.ok': 'Quase lá, pratique mais',
    'results.incorrect': 'Tente escutar novamente',
    'results.subtitle.stayTuned': 'Espere alguns segundos',
    'results.subtitle.processing': 'Espere alguns segundos',
    'results.subtitle.preProcessing': 'Idioma do jogador',
    'results.subtitle.sorry': 'Não foi possível reconhecer',
    'results.subtitle.best': 'Excelente',
    'results.subtitle.alright': 'Reconhecível, pratique mais',
    'results.subtitle.ok': 'Tente escutar novamente',
    'results.subtitle.incorrect': 'Tente escutar novamente',
    'share.copied': 'Copiado',
    'options.share': 'Compartilhe',
    'options.showFootballers': 'Selecione o país',
  },
  es: {
    'header.pronounce': 'Pronounce',
    'header.footballers': 'Footballers',
    'header.nameRight': 'Name Right',
    'system.connecting': 'Connecting to the server',
    'selector.allCountries': 'All countries',
    'speak.title': 'SPEAK',
    'speak.subtitle': 'Tap/click to record',
    'speakNow.title': 'SPEAK NOW',
    'speakNow.subtitle': 'Tap/click to stop',
    'alt.listen': 'Listen',
    'alt.speak': 'Speak now',
    'footer.whyThisMatters': 'Why this matters?',
    'controller.next': 'Next',
    'results.stayTuned': 'Stay tuned',
    'results.processing': 'Processing...',
    'results.sorry': 'Sorry!',
    'results.best': 'Good job!',
    'results.alright': 'Alright',
    'results.ok': "It's ok!",
    'results.incorrect': 'Not quite right!',
    'results.subtitle.stayTuned': 'not supported yet.',
    'results.subtitle.processing': 'Please wait a few seconds.',
    'results.subtitle.preProcessing': "Player's name language",
    'results.subtitle.sorry': "We couldn't recognise it",
    'results.subtitle.best': 'Sounds really good!',
    'results.subtitle.alright': 'Recognisable, practice more!',
    'results.subtitle.ok': 'Try listening again',
    'results.subtitle.incorrect': 'Try listening again.',
    'share.copied': 'Copied',
  },
};

export const getBrowserLanguage = () => {
  if (!window.navigator.language) {
    return 'en';
  }
  return window.navigator.language.split('-')[0];
};

export default function getCopy(key: string): string {
  const translations = translationMap[getBrowserLanguage()] || translationMap['en'];
  return translations[key] || key;
};
