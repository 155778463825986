import React from 'react';
import styled from 'styled-components';
import { LanguageCode, languageOptions } from '../languages';
import { LanguageActionType, useLanguage } from '../context/LanguageContext';

const LanguageSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--fontColour);
  width: 364px;
`;

const Select = styled.select`
  width: 100%;

  user-select: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  padding: 16px 24px;
  appearance: none;
  text-align: center;

  color: var(--fontColour);
  border-radius: var(--borderRadius);
  background-color: var(--primarySix);

  border: 1px solid var(--grayTwo);
  box-shadow: 3px 4px 0 var(--grayTwo);

  :hover,
  :focus {
    background-color: var(--primary);
  }
`;

const LanguageTitle = styled.span`
  color: var(--black);
  margin-bottom: 8px;
`;

type LanguageDropdownProps = {
  selected: LanguageCode;
  onChange: (languageCode: LanguageCode) => void;
};

const NativeLanguageDropdown = ({ selected, onChange }: LanguageDropdownProps) => (
  <Select
    placeholder="Select a language"
    value={selected}
    onChange={(e) => onChange(e.target.value as LanguageCode)}
  >
    {languageOptions.map((option) => (
      <option key={option.key} value={option.value}>
        {option.text}
      </option>
    ))}
  </Select>
);

export default function LanguageSelection() {
  const { dispatch, state } = useLanguage();

  return (
    <LanguageSelectionContainer>
      <LanguageTitle>Select a language</LanguageTitle>
      <NativeLanguageDropdown
        selected={state.language.value}
        onChange={(languageCode: LanguageCode) =>
          dispatch({ type: LanguageActionType.CHANGE, languageCode: languageCode })
        }
      />
    </LanguageSelectionContainer>
  );
}
