import { getBrowserLanguage } from './get-copy';

export function getLanguageCodeToLanguage(languageCode: string, countryKey?: string) {
  if (
    countryKey &&
    LanguageCodeToLanguage[getBrowserLanguage()] &&
    LanguageCodeToLanguage[getBrowserLanguage()][`${languageCode}-${countryKey}`]
  ) {
    return LanguageCodeToLanguage[getBrowserLanguage()][`${languageCode}-${countryKey}`];
  }

  return LanguageCodeToLanguage['en'][languageCode];
}

export const LanguageCodeToLanguage = {
  en: {
    'ar-QA': 'Arabic (Qatar)',
    'es-EC': 'Spanish (Ecuador)',
    'fr-FR-senegal': 'French (Senegal)',
    'nl-NL': 'Dutch (Netherlands)',
    'nl-NL-belgium': 'Dutch (Belgium)',
    'en-GB': 'English (UK)',
    'fa-IR': 'Farsi (Iran)',
    'en-US': 'English (US)',
    'en-GB-wales': 'English (UK)',
    'es-AR': 'Spanish (Argentina)',
    'ar-SA': 'Arabic (Saudi Arabia)',
    'es-MX': 'Spanish (Mexico)',
    'pl-PL': 'Polish (Poland)',
    'fr-FR': 'French (France)',
    'en-AU': 'English (Australia)',
    'ar-TN': 'Arabic (Tunisia)',
    'da-DK': 'Danish (Denmark)',
    'es-ES': 'Spanish (Spain)',
    'es-CR': 'Spanish (Costa Rica)',
    'de-DE': 'German (Germany)',
    'ja-JP': 'Japanese (Japan)',
    'fr-BE': 'French (Belgium)',
    'nl-BE': 'Dutch (Belgium)',
    'en-CA': 'English (Canada)',
    'fr-CA': 'French (Canada)',
    'ar-MA': 'Arabic (Morocco)',
    'hr-HR': 'Croatian (Croatia)',
    'pt-BR': 'Portuguese (Brazil)',
    'sr-RS': 'Serbian (Serbia)',
    'fr-CH': 'French (Switzerland)',
    'de-CH': 'German (Switzerland)',
    'fr-FR-cameroon': 'French (Cameroon)',
    'en-US-cameroon': 'English (Cameroon)',
    'pt-PT': 'Portuguese (Portugal)',
    'en-GH': 'English (Ghana)',
    'es-UY': 'Spanish (Uruguay)',
    'ko-KR': 'Korean (South Korea)',
  },
  pt: {
    'ar-QA': 'Árabe (Catar)',
    'es-EC': 'Espanhol (Equador)',
    'fr-FR-senegal': 'Francês (Senegal)',
    'nl-NL': 'Holandês (Holanda)',
    'nl-NL-belgium': 'Holandês (Bélgica)',
    'en-GB': 'Inglês (Inglaterra)',
    'fa-IR': 'Farsi (Irã)',
    'en-US': 'Inglês (Estados Unidos)',
    'en-GB-wales': 'Inglês (País de Gales)',
    'es-AR': 'Espanhol (Argentina)',
    'ar-SA': 'Árabe (Arábia Saudita)',
    'es-MX': 'Espanhol (México)',
    'pl-PL': 'Polonês (Polônia)',
    'fr-FR': 'Francês (França)',
    'en-AU': 'Inglês (Austrália)',
    'ar-TN': 'Árabe (Tunísia)',
    'da-DK': 'Dinamarquês (Dinamarca)',
    'es-ES': 'Espanhol (Espanha)',
    'es-CR': 'Espanhol (Costa Rica)',
    'de-DE': 'Alemão (Alemanha)',
    'ja-JP': 'Japonês (Japão)',
    'fr-BE': 'Francês (Bélgica)',
    'nl-BE': 'Holandês (Bélgica)',
    'en-CA': 'Inglês (Canadá)',
    'fr-CA': 'Francês (Canadá)',
    'ar-MA': 'Árabe (Marrocos)',
    'hr-HR': 'Croata (Croácia)',
    'pt-BR': 'Português (Brasil)',
    'sr-RS': 'Sérvio (Sérvia)',
    'fr-CH': 'Francês (Suíça)',
    'de-CH': 'Alemão (Suíça)',
    'fr-FR-cameroon': 'Francês (Camarōes)',
    'en-US-cameroon': 'Inglês (Camarōes)',
    'pt-PT': 'Português (Portugal)',
    'en-GH': 'Inglês (Gana)',
    'es-UY': 'Espanhol (Uruguai)',
    'ko-KR': 'Coreano (Coréia do Sul)',
  },
};

const countryKeyToName = {
  en: {
    qatar: 'Qatar',
    ecuador: 'Ecuador',
    senegal: 'Senegal',
    netherlands: 'Netherlands',
    england: 'England',
    iran: 'Iran',
    'united-states': 'United States',
    wales: 'Wales',
    argentina: 'Argentina',
    'saudi-arabia': 'Saudi Arabia',
    mexico: 'Mexico',
    poland: 'Poland',
    france: 'France',
    australia: 'Australia',
    tunisia: 'Tunisia',
    denmark: 'Denmark',
    spain: 'Spain',
    'costa-rica': 'Costa Rica',
    germany: 'Germany',
    japan: 'Japan',
    belgium: 'Belgium',
    canada: 'Canada',
    morocco: 'Morocco',
    croatia: 'Croatia',
    brazil: 'Brazil',
    serbia: 'Serbia',
    switzerland: 'Switzerland',
    cameroon: 'Cameroon',
    portugal: 'Portugal',
    ghana: 'Ghana',
    uruguay: 'Uruguay',
    'south-korea': 'South Korea',
  },
  pt: {
    qatar: 'Catar',
    ecuador: 'Equador',
    senegal: 'Senegal',
    netherlands: 'Países Baixos',
    england: 'Inglaterra',
    iran: 'Irã',
    'united-states': 'Estados Unidos',
    wales: 'País de Gales',
    argentina: 'Argentina',
    'saudi-arabia': 'Arábia Saudita',
    mexico: 'México',
    poland: 'Polônia',
    france: 'França',
    australia: 'Austrália',
    tunisia: 'Tunísia',
    denmark: 'Dinamarca',
    spain: 'Espanha',
    'costa-rica': 'Costa Rica',
    germany: 'Alemanha',
    japan: 'Japão',
    belgium: 'Bélgica',
    canada: 'Canadá',
    morocco: 'Marrocos',
    croatia: 'Croácia',
    brazil: 'Brasil',
    serbia: 'Sérvia',
    switzerland: 'Suiça',
    cameroon: 'Camarões',
    portugal: 'Portugal',
    ghana: 'Gana',
    uruguay: 'Uruguai',
    'south-korea': 'Coréia do Sul',
  },
};

export function getCountryNameFromKey(countryKey: string) {
  let language = getBrowserLanguage();
  if (!countryKeyToName[language]) {
    language = 'en';
  }

  return countryKeyToName[language][countryKey];
}

export function getAllCountriesHardCoded(): { [countryKey: string]: string } {
  let language = getBrowserLanguage();
  if (!countryKeyToName[language]) {
    language = 'en';
  }

  return countryKeyToName[language];
}

export const CountriesKeysCache = [
  'qatar',
  'ecuador',
  'senegal',
  'netherlands',
  'england',
  'iran',
  'united-states',
  'wales',
  'argentina',
  'saudi-arabia',
  'mexico',
  'poland',
  'france',
  'australia',
  'tunisia',
  'denmark',
  'spain',
  'costa-rica',
  'germany',
  'japan',
  'belgium',
  'canada',
  'morocco',
  'croatia',
  'brazil',
  'serbia',
  'switzerland',
  'cameroon',
  'portugal',
  'ghana',
  'uruguay',
  'south-korea',
];
