import React from 'react';

import styled, { keyframes } from 'styled-components';
import { DefaultButton, DefaultButtonProps } from './DefaultButton';

const BigButtonCommon = styled(DefaultButton as any)`
  gap: 6px;

  padding: 10px 24px 10px 18px;
  box-sizing: border-box;
`;

const Primary = styled(BigButtonCommon as any)`
  border: 1px solid var(--black);
`;

const Secondary = styled(BigButtonCommon as any)`
  background-color: var(--black);
  color: var(--primary);
  border: 1px solid var(--primary);
`;

const breatheAnimation = keyframes`
  0% {
    color: var(--black);
    background-color: var(--primary);
  }
  50% {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 0.9);
  }
  100% {
    color: var(--black);
    background-color: var(--primary);
  }
`;

const PrimaryButtonHeld = styled(Primary as any)`
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border: 4px solid var(--black);
`;

const SecondaryButtonHeld = styled(Secondary as any)`
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border: 4px solid var(--black);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  flex-grow: 1;
`;

const BigText = styled.span`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 600;
`;

const SmallText = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

const ButtonIcon = styled.span`
  font-size: 32px;
`;

type BigButtonProps = {
  text: string;
  subText: string;
  isHeld?: boolean;
} & DefaultButtonProps;

export const PrimaryButton = ({ onClick, icon, text, subText, isHeld }: BigButtonProps) => {
  const PrimaryButtonComponent = isHeld ? PrimaryButtonHeld : Primary;
  return (
    <PrimaryButtonComponent onClick={onClick}>
      <ButtonIcon className="material-symbols-outlined">{icon}</ButtonIcon>
      <TextWrapper>
        <BigText>{text}</BigText>
        <SmallText>{subText}</SmallText>
      </TextWrapper>
    </PrimaryButtonComponent>
  );
};

export const SecondaryButton = ({ onClick, icon, text, subText, isHeld }: BigButtonProps) => {
  const SecondaryButtonComponent = isHeld ? SecondaryButtonHeld : Secondary;
  return (
    <SecondaryButtonComponent onClick={onClick}>
      <ButtonIcon className="material-symbols-outlined">{icon}</ButtonIcon>
      <TextWrapper>
        <BigText>{text}</BigText>
        <SmallText>{subText}</SmallText>
      </TextWrapper>
    </SecondaryButtonComponent>
  );
};
