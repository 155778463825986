import React from 'react';
import styled from 'styled-components';

import { getLanguageCodeToLanguage } from '../utils/countriesLanguage';
import getCopy from '../utils/get-copy';

type ResultProps = {
  confidence: number;
  languageCode: string;

  isPreProcessing?: boolean;
  isProcessing?: boolean;
  transcript?: string;
};

const ResultContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 32px;

  width: 290px;
  height: 42px;

  gap: 16px;
  background: var(--grayThree);
  border-radius: 12px;

  margin-top: 40px;
`;

const Icon = styled.span`
  font-size: 40px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
`;

const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const THRESHOLD = {
  BEST: 0.9,
  ALRIGHT: 0.75,
  OK: 0.46,
};

function getTitle(confidence: number, isProcessing: boolean, isPreProcessing: boolean, languageCode: string) {
  if (languageCode === 'fa-IR') {
    return getCopy('results.stayTuned');
  }

  if (isPreProcessing) {
    return getLanguageCodeToLanguage(languageCode);
  }

  if (isProcessing) {
    return getCopy('results.processing');
  }

  if (confidence < 0) {
    return getCopy('results.sorry');
  }

  if (confidence >= THRESHOLD.BEST) {
    return getCopy('results.best');
  } else if (confidence >= THRESHOLD.ALRIGHT) {
    return getCopy('results.alright');
  } else if (confidence >= THRESHOLD.OK) {
    return getCopy('results.ok');
  }
  return getCopy('results.incorrect');
}

function getSubtitle(
  confidence: number,
  isProcessing: boolean,
  isPreProcessing: boolean,
  languageCode: string
) {
  if (languageCode === 'fa-IR') {
    return getLanguageCodeToLanguage(languageCode) + ' ' + getCopy('results.subtitle.stayTuned');
  }

  if (isPreProcessing) {
    return getCopy('results.subtitle.preProcessing');
  }

  if (isProcessing) {
    return getCopy('results.subtitle.processing');
  }

  if (confidence < 0) {
    return getCopy('results.subtitle.sorry');
  }

  if (confidence >= THRESHOLD.BEST) {
    return getCopy('results.subtitle.best');
  } else if (confidence >= THRESHOLD.ALRIGHT) {
    return getCopy('results.subtitle.alright');
  } else if (confidence >= THRESHOLD.OK) {
    return getCopy('results.subtitle.ok');
  }

  return getCopy('results.subtitle.incorrect');
}

function getIcon(confidence: number, isProcessing: boolean, isPreProcessing: boolean) {
  if (isPreProcessing) {
    return 'public';
  }

  if (isProcessing) {
    return 'refresh';
  }

  if (confidence >= THRESHOLD.BEST) {
    return 'military_tech';
  } else if (confidence >= THRESHOLD.ALRIGHT) {
    return 'thumb_up';
  } else if (confidence >= THRESHOLD.OK) {
    return 'thumbs_up_down';
  }
  return 'sentiment_dissatisfied';
}

export default function Results({
  confidence,
  isProcessing = false,
  isPreProcessing = true,
  languageCode,
}: ResultProps) {
  return (
    <ResultContainer>
      <Icon className="material-symbols-outlined">{getIcon(confidence, isProcessing, isPreProcessing)}</Icon>
      <TextWrapper>
        <Title>{getTitle(confidence, isProcessing, isPreProcessing, languageCode)}</Title>
        <SubTitle>{getSubtitle(confidence, isProcessing, isPreProcessing, languageCode)}</SubTitle>
      </TextWrapper>
    </ResultContainer>
  );
}
