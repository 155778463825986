import * as React from 'react';
import { Language, LanguageCode, languages } from '../languages';

export enum LanguageActionType {
  CHANGE = 'change',
}

type Action = {
  type: LanguageActionType;
  languageCode: LanguageCode;
};
type Dispatch = (action: Action) => void;
type State = { language: Language };
type LanguageProviderProps = { children: React.ReactNode };

const LanguageStateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function languageReducer(state: State, action: Action) {
  switch (action.type) {
    case LanguageActionType.CHANGE: {
      return { language: languages[action.languageCode] };
    }
  }
}

function LanguageProvider({ children }: LanguageProviderProps) {
  const [state, dispatch] = React.useReducer(languageReducer, { language: languages[LanguageCode.PT_BR] });
  const value = { state, dispatch };
  return <LanguageStateContext.Provider value={value}>{children}</LanguageStateContext.Provider>;
}

function useLanguage() {
  const context = React.useContext(LanguageStateContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}

export { LanguageProvider, useLanguage };
