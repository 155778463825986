import React from 'react';
import styled from 'styled-components';
import { DefaultButton, DefaultButtonProps } from './DefaultButton';

const Tertiary = styled(DefaultButton as any)`
  box-sizing: border-box;

  padding: 11px 16px;
  font-size: 14px;
  height: auto;
  width: ${(props) => (props.width ? props.width : '100%')};
  border: 1px solid var(--black);
  background-color: transparent;
  box-shadow: none;
  color: var(--black);
`;

const RoundIcon = styled(DefaultButton as any)`
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 100%;
  border: ${(props) => (props.toggled ? '3px' : '1px')} solid var(--black);
  background-color: transparent;
  color: var(--black);
  width: 48px;
  height: 48px;

  span {
    font-size: 18px;
  }
`;

const ButtonIconBefore = styled.span`
  padding-right: 8px;
`;
const ButtonIconAfter = styled.span`
  padding-left: 8px;
`;

export const TertiaryButton = ({
  children,
  onClick,
  icon,
  width,
  iconPosition = 'before',
}: DefaultButtonProps) => {
  return (
    <Tertiary onClick={onClick} width={width}>
      {icon && iconPosition === 'before' && (
        <ButtonIconBefore className="material-symbols-outlined">{icon}</ButtonIconBefore>
      )}
      {children}
      {icon && iconPosition === 'after' && (
        <ButtonIconAfter className="material-symbols-outlined">{icon}</ButtonIconAfter>
      )}
    </Tertiary>
  );
};

type IconToggleProps = {
  toggled: boolean;
} & DefaultButtonProps;

export const RoundIconToggle = ({
  onClick,
  icon,
  toggled = false,
}: IconToggleProps) => {
  return (
    <RoundIcon
      onClick={onClick}
      toggled={toggled}
    >
      <span className="material-symbols-outlined">{icon}</span>
    </RoundIcon>
  );
};
