import React from 'react';

import Input from '../../components/generic/Input';
import { NameActionType, useName } from '../context/NameContext';
import { useLanguage } from '../context/LanguageContext';
import styled from 'styled-components';

const NameTitle = styled.div`
  margin-bottom: 8px;

  span {
    font-weight: 600;
  }
`;

const NameSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NameSelectionController = () => {
  const { state: nameState, dispatch } = useName();
  const { state: languageState } = useLanguage();

  return (
    <NameSelectionContainer>
      <NameTitle>
        Enter name in <span>{languageState.language.text}</span> below
      </NameTitle>
      <Input
        name={nameState.name}
        initialValue={nameState.name}
        placeholder={languageState.language.placeholder}
        maxChar={30}
        onChange={({ value }) => dispatch({ type: NameActionType.CHANGE, name: value })}
      />
    </NameSelectionContainer>
  );
};
