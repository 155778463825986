import { listen } from './audio/web-audio';
import { fetchBlob } from './utils/fetcher';
import { amplifyMedia } from './audio/amplify';
import { API_URL } from './utils/api-url';
import { log as logger } from './utils/logger';

export const tryListen = async (name, languageCode) => {
  const synthesisResult = await fetch(`${API_URL}/synthesise`, {
    method: 'POST', body: JSON.stringify({
      name,
      languageCode,
    }), headers: { 'Content-type': 'application/json' },
  });

  let audioFileUrl = null;
  try {
    const jsonResult = await synthesisResult.json();
    if (!jsonResult || jsonResult.status === 'SYNTHESIS_STARTED' || jsonResult.status === 'FILE_NOT_AVAILABLE') {
      await listen(name, languageCode);
      return;
    }

    if (jsonResult.status === 'SYNTHESIS_READY') {
      audioFileUrl = jsonResult.fileUrl;
    }
  } catch (e) {
    console.error(e);
    logger('[synthesise] Not possible to get fileUrl from server');
  }

  if (!audioFileUrl) {
    logger('[synthesise] File not available, using browser synthesis', name);
    await listen(name, languageCode);
    return;
  }

  try {
    const audioOnHtml = new Audio();
    if (audioOnHtml) {
      const sourceElement = document.createElement('source');
      audioOnHtml.appendChild(sourceElement);
      sourceElement.src = await fetchBlob(audioFileUrl);
      sourceElement.type = 'audio/mpeg';

      audioOnHtml.onerror = (e) => {
        console.error(e);
        logger('[synthesise] error loading file url, using browser synthesis', name);
        listen(name, languageCode);
      };
      audioOnHtml.onloadeddata = () => {
        logger('[synthesise] playing audio from remote file', audioOnHtml.src);
      };
      amplifyMedia(audioOnHtml, 4);
      audioOnHtml.load();
      audioOnHtml.play();
    }
  } catch (e) {
    console.error(e);
    logger('[synthesise] Error reproducing audio from server');
    listen(name, languageCode);
  }
};