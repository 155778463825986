import React from 'react';
import styled from 'styled-components';
import Logo from '../images/logo.svg';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 16px;
  height: 60px;
`;

const Image = styled.img`
  height: 100%;
`;

export default () => (
  <LogoContainer onClick={() => window.location.href = 'https://saymynameapp.com'}>
    <Image src={Logo} />
  </LogoContainer>
);
