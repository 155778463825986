export type Language = {
  key: string;
  text: string;
  value: LanguageCode;
  placeholder: string;
  image?: {
    avatar: boolean;
    src: any;
    size?: string;
  };
  urlCode: string;
};

export enum LanguageCode {
  PT_BR = 'pt-BR',
  EN_GB = 'en-GB',
  SV_SE = 'sv-SE',
  HI_IN = 'hi-IN',
  ZH_CN = 'zh-CN',
  ES_ES = 'es-ES',
  VI_VN	= 'vi-VN',
}

export const languages: { [key: string]: Language } = {
  [LanguageCode.PT_BR]: {
    key: LanguageCode.PT_BR,
    text: 'Portuguese (BR)',
    value: LanguageCode.PT_BR,
    placeholder: 'Nome',
    urlCode: 'br',
  },
  [LanguageCode.EN_GB]: {
    key: LanguageCode.EN_GB,
    text: 'English (UK)',
    value: LanguageCode.EN_GB,
    placeholder: 'Name',
    urlCode: 'en',
  },
  [LanguageCode.ES_ES]: {
    key: LanguageCode.ES_ES,
    text: 'Spanish',
    placeholder: 'Nombre',
    value: LanguageCode.ES_ES,
    urlCode: 'es',
  },
  [LanguageCode.SV_SE]: {
    key: LanguageCode.SV_SE,
    text: 'Swedish',
    placeholder: 'namn',
    value: LanguageCode.SV_SE,
    urlCode: 'sv',
  },
  [LanguageCode.HI_IN]: {
    key: LanguageCode.HI_IN,
    text: 'Hindi',
    placeholder: 'नाम',
    value: LanguageCode.HI_IN,
    urlCode: 'hi',
  },
  [LanguageCode.ZH_CN]: {
    key: LanguageCode.ZH_CN,
    text: 'Chinese (Mandarin)',
    value: LanguageCode.ZH_CN,
    placeholder: '姓名',
    urlCode: 'zh',
  },
  [LanguageCode.VI_VN]: {
    key: LanguageCode.VI_VN,
    text: 'Vietnamese (Vietnam)',
    value: LanguageCode.VI_VN,
    placeholder: 'tên',
    urlCode: 'vi',
  },
};

export const languageOptions: Language[] = [
  languages[LanguageCode.PT_BR],
  languages[LanguageCode.EN_GB],
  languages[LanguageCode.ES_ES],
  languages[LanguageCode.ZH_CN],
  languages[LanguageCode.HI_IN],
  languages[LanguageCode.SV_SE],
  languages[LanguageCode.VI_VN],
];
