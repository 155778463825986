import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import CopyIcon from '../images/copy.svg';
import { HistoryParams } from '../types';

function copy(value: string) {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

const Container = styled.div`
  min-height: 125px;

  text-align: center;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  margin: 0 auto 8px;
  color: var(--fontColour);
  font-weight: bold;
`;

const ShareBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: var(--fontColour);
  background-color: var(--primary);
  border: 1px solid var(--grayTwo);
  padding: 16px;
  border-radius: 6px;
`;

const Copy = styled.img`
  color: var(--fontColour);
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const Link = styled.div``;

const Copied = styled.div`
  margin-top: 4px;
  color: var(--fontColour);
  font-weight: bold;
`;

const Empty = styled(Copied as any)`
  color: transparent;
`;

const Share: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const { name, language } = useParams<HistoryParams>();
  const [link, setLink] = useState(`https://saymyname.io/${language}/${name}`);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2500);
    }
    setLink(`https://saymyname.io/${language}/${name || ''}`);
  }, [name, language, copied]);

  return (
    <Container>
      <Header>Click to copy your link</Header>
      <ShareBox
        onClick={() => {
          copy(link);
          setCopied(true);
        }}
      >
        <Copy src={CopyIcon} />
        <Link>{link}</Link>
      </ShareBox>
      {copied ? <Copied>Copied!</Copied> : <Empty>.</Empty>}
    </Container>
  );
};

export default Share;
