import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LanguageActionType, useLanguage } from '../context/LanguageContext';
import { Language, LanguageCode, languages } from '../languages';
import { NameActionType, useName } from '../context/NameContext';
import { HistoryParams } from '../../types';
import { log } from '../utils/logger';

const APP_DEFAULT_LANGUAGE = languages[LanguageCode.PT_BR];
const allLanguageURLCodes = Object.values(languages).map((l) => l.urlCode);

function findLanguageFromParam(param: string): Language {
  const languageFromParam: Language | undefined = Object.values(languages).find((l) => l.urlCode === param);
  return languageFromParam || APP_DEFAULT_LANGUAGE;
}

export default function PathController() {
  const { language: languageFromParams, name: nameFromParams } = useParams<HistoryParams>();
  const navigate = useNavigate();

  const { state: languageState, dispatch: dispatchLanguage } = useLanguage();
  const { state: nameState, dispatch: dispatchName } = useName();

  useEffect(() => {
    if (languageFromParams && allLanguageURLCodes.includes(languageFromParams)) {
      dispatchLanguage({
        type: LanguageActionType.CHANGE,
        languageCode: findLanguageFromParam(languageFromParams).value,
      });
    }

    if (nameFromParams) {
      dispatchName({
        type: NameActionType.CHANGE,
        name: nameFromParams,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newName = (nameState.name && nameState.name.toLowerCase()) || '';

    log('Changing app path', `/${languageState.language.urlCode}/${newName}`);

    navigate(`/${languageState.language.urlCode}/${newName}`);
    // eslint-disable-next-line
  }, [nameState.name, languageState.language]);

  return <></>;
}
