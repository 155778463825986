import { styled } from 'styled-components';

import PathController from './container/PathContainer';
import CoreContainer from './container/CoreContainer';
import { NameSelectionController } from './container/NameContainer';
import LanguageSelection from './container/LanguageContainer';
import { LanguageProvider } from './context/LanguageContext';
import { NameProvider } from './context/NameContext';
import { RecogniserProvider } from './context/RecogniserContext';

import { TertiaryButton } from '../components/generic/buttons/SmallButon';
import Share from '../components/Share';

const MainSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const ThirdSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary);

  padding: 40px;

  background-image: linear-gradient(to top right, #84e1bc, #fce96a, #f9a8d4);
`;

const LanguageNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default function AppCore() {
  return (
    <NameProvider>
      <LanguageProvider>
        <RecogniserProvider>
          <PathController />
          <MainSection>
            <LanguageNameContainer>
              <NameSelectionController />
              <LanguageSelection />
            </LanguageNameContainer>
            <CoreContainer />
            <div style={{ marginBottom: '30px' }}></div>
            <TertiaryButton
              width="364px"
              onClick={() => window.open('https://saymyname.io/edit', '_blank').focus()}
            >
              Create your name card now
            </TertiaryButton>
          </MainSection>
          <ThirdSection>
            <Share />
          </ThirdSection>
        </RecogniserProvider>
      </LanguageProvider>
    </NameProvider>
  );
}
