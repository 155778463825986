import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InputBox: any = styled.input`
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  text-transform: capitalize;
  text-align: center;

  padding: 12px 12px;
  width: 364px;

  z-index: 2;
  
  border-radius: var(--borderRadius);
  color: var(--fontColour);
  border: 1px solid var(--grayTwo);
  box-shadow: 3px 4px 0 var(--grayTwo);
  background-color: var(--primarySix);

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;


type InputFieldProps = {
  name: string;
  placeholder: string;
  initialValue?: string;
  onChange?: ({ name, value }: any) => void;
  maxChar?: number;
};

const Input: React.FC<InputFieldProps> = ({
  name = 'generic',
  maxChar,
  placeholder,
  initialValue,
  onChange,
}: InputFieldProps) => {
  const [value, setValue] = useState<string>(initialValue || '');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <InputContainer>
      <InputBox
        type="text"
        maxLength={maxChar}
        placeholder={placeholder}
        value={value}
        onBlur={(e: React.FormEvent<HTMLInputElement>) =>
          onChange && onChange({ name, value: e.currentTarget.value })
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            onChange && onChange({ name, value: e.currentTarget.value });
          }
        }}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
      />
    </InputContainer>
  );
};

export default Input;
