import * as React from 'react';
import { RecogniserResult } from '../../types';

export enum RecogniserActionType {
  START_RECORDING = 'START_RECORDING',
  STOP_RECORDING = 'STOP_RECORDING',
  SAVE_RESULT = 'SAVE_RESULT',
}

type Action = RecogniserAction;

type RecogniserAction = {
  type: RecogniserActionType;
  recordingId?: string;
  result?: RecogniserResult;
};

type Dispatch = (action: RecogniserAction) => void;
type State = { recordingId?: string; result?: RecogniserResult };
type RecogniserProviderProps = { children: React.ReactNode };

const RecogniserStateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
);

function recogniserReducer(state: State, action: Action) {
  switch (action.type) {
    case RecogniserActionType.START_RECORDING: {
      if (action.recordingId) {
        return { ...state, recordingId: action.recordingId };
      }
      return state;
    }
    case RecogniserActionType.STOP_RECORDING: {
      return { ...state, recordingId: undefined };
    }
    case RecogniserActionType.SAVE_RESULT: {
      return { ...state, result: action.result };
    }
  }
}

function RecogniserProvider({ children }: RecogniserProviderProps) {
  const [state, dispatch] = React.useReducer(recogniserReducer, {});
  const value = { state, dispatch };
  return <RecogniserStateContext.Provider value={value}>{children}</RecogniserStateContext.Provider>;
}

function useRecogniser() {
  const context = React.useContext(RecogniserStateContext);
  if (context === undefined) {
    throw new Error('useRecogniser must be used within a RecogniserProvider');
  }
  return context;
}

export { RecogniserProvider, useRecogniser };
