import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// mechanisms
import { levenshteinDistance } from '../utils/levenshtein';
import { RecogniserResult } from '../../types';
import { SpeakRecorder } from '../audio/SpeakRecorder';
import getCopy from '../utils/get-copy';
import { API_URL } from '../utils/api-url';

// core
import { RecogniserActionType, useRecogniser } from '../context/RecogniserContext';
import { useName } from '../context/NameContext';
import { useLanguage } from '../context/LanguageContext';
import { tryListen } from '../common';
import Results from './ResultsContainer';

// components
import { PrimaryButton, SecondaryButton } from '../../components/generic/buttons/BigButton';

const debug = console.debug;
const log = console.log;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CoreContainer: React.FC = () => {
  const { state: nameState } = useName();
  const { state: languageState } = useLanguage();

  const [speakRecorder, setSpeakRecorder] = useState<SpeakRecorder>();
  const { state: recogniserState, dispatch: recogniserDispatcher } = useRecogniser();

  const [isProcessingRecognition, setIsProcessingRecognition] = useState<boolean>();
  const [started, setStarted] = useState<boolean>();

  useEffect(() => {
    const newSpeakRecorder = new SpeakRecorder();
    newSpeakRecorder.init();
    setSpeakRecorder(newSpeakRecorder);
  }, []);

  useEffect(() => {
    recogniserDispatcher({
      type: RecogniserActionType.SAVE_RESULT,
      result: undefined,
    });
    setStarted(false);
    setIsProcessingRecognition(false);
  }, [nameState.name, recogniserDispatcher]);

  const onClickSpeak = (e) => {
    setStarted(true);
    speakRecorder.start();
    const id = uuidv4();
    recogniserDispatcher({ type: RecogniserActionType.START_RECORDING, recordingId: id });
    e.stopPropagation();
  };

  async function pollForResult(recordingId) {
    let resultsFromPolling: RecogniserResult | undefined = undefined;
    try {
      let result;
      let attempts = 10;
      while (!result && attempts > 0) {
        console.log('attempt', attempts);
        const responseOnPoll = await fetch(`${API_URL}/${recordingId}/poll`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (responseOnPoll.status === 200) {
          result = await responseOnPoll.json();
          debug('Result from polling', result);
          console.log(result);
        }
        await new Promise((res) => setTimeout(res, 500));
        attempts--;
      }
      resultsFromPolling = result;
    } catch (e) {
      log('Error polling results');
    }

    const checkResult = (recogniserResult?: RecogniserResult) => {
      if (!recogniserResult) {
        recogniserDispatcher({
          type: RecogniserActionType.SAVE_RESULT,
          result: { confidence: -1, transcript: '' },
        });
        return;
      }

      const transcript = recogniserResult.transcript.trim().toLowerCase();
      const matchingName = nameState.name.trim().toLowerCase();
      if (levenshteinDistance(transcript, matchingName) <= 2) {
        recogniserDispatcher({
          type: RecogniserActionType.SAVE_RESULT,
          result: recogniserResult,
        });
      } else {
        recogniserDispatcher({
          type: RecogniserActionType.SAVE_RESULT,
          result: { confidence: -1, transcript: '' },
        });
      }
    };

    checkResult(resultsFromPolling);
    setIsProcessingRecognition(false);
    return;
  }

  const onClickStop = async (e) => {
    setStarted(false);
    setIsProcessingRecognition(true);
    console.log('Stopping');

    try {
      await speakRecorder.stop(recogniserState.recordingId, nameState.name, languageState.language.value);
    } catch (e) {
      console.error(e);
      console.log('Error stopping');
      setIsProcessingRecognition(false);
      recogniserDispatcher({
        type: RecogniserActionType.SAVE_RESULT,
        result: { confidence: -1, transcript: '' },
      });
      setStarted(false);
      return;
    }
    recogniserDispatcher({ type: RecogniserActionType.STOP_RECORDING });

    pollForResult(recogniserState.recordingId);

    e.stopPropagation();
  };

  let text = getCopy('speak.title');
  let subText = getCopy('speak.subtitle');

  if (started) {
    text = getCopy('speakNow.title');
    subText = getCopy('speakNow.subtitle');
  }

  return (
    <CoreWrapper>
      <ButtonContainer>
        <PrimaryButton
          onClick={() => tryListen(nameState.name, languageState.language.value)}
          icon="hearing"
          text={getCopy('listen.title')}
          subText={getCopy('listen.subtitle')}
        />
        <SecondaryButton
          onClick={started ? onClickStop : onClickSpeak}
          icon="keyboard_voice"
          text={text}
          subText={subText}
          isHeld={!!started}
        />
      </ButtonContainer>
      {recogniserState.result && (
        <Results
          isProcessing={isProcessingRecognition}
          confidence={recogniserState.result ? recogniserState.result.confidence : -1}
          isPreProcessing={!recogniserState.result && !isProcessingRecognition}
          languageCode={languageState.language.value}
        />
      )}
    </CoreWrapper>
  );
};

export default CoreContainer;
