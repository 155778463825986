import * as React from 'react';

export enum NameActionType {
  CHANGE = 'change',
}
type Action = {
  type: NameActionType;
  name: string;
};
type NameDispatch = (action: Action) => void;
type NameState = { name: string };
type NameProviderProps = { children: React.ReactNode };

const NameStateContext = React.createContext<{ state: NameState; dispatch: NameDispatch } | undefined>(
  undefined
);

function nameReducer(state: NameState, action: Action) {
  switch (action.type) {
    case NameActionType.CHANGE: {
      return { name: action.name };
    }
  }
}

function NameProvider({ children }: NameProviderProps) {
  const [state, dispatch] = React.useReducer(nameReducer, { name: '' });
  const value = { state, dispatch };
  return <NameStateContext.Provider value={value}>{children}</NameStateContext.Provider>;
}

function useName() {
  const context = React.useContext(NameStateContext);
  if (context === undefined) {
    throw new Error('useName must be used within a NameProvider');
  }
  return context;
}

export { NameProvider, useName };
