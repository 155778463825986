export async function fetchBlob(audioFileUrl) {
  let actualAudioFileUrl = audioFileUrl;
  if (process.env.NODE_ENV === 'development') {
    actualAudioFileUrl = audioFileUrl.replace('https', 'http');
  }
  return (
    fetch(actualAudioFileUrl, {
      headers: {
        'Content-Type': 'audio/mpeg',
      },
    })
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => {
        console.log('Create object URL for blob');
        return URL.createObjectURL(blob);
      })
  );
}
