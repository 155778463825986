export function amplifyMedia(mediaElem, multiplier) {
  const context = new window.AudioContext();

  let source;
  try {
    source = context.createMediaElementSource(mediaElem);
  } catch (e) {
    console.error(e);
    return;
  }

  const result = {
    context: context,
    source,
    gain: context.createGain(),
    media: mediaElem,
    amplify: function (multiplier) {
      result.gain.gain.value = multiplier;
    },
    getAmpLevel: function () {
      return result.gain.gain.value;
    },
  };
  result.source.connect(result.gain);
  result.gain.connect(context.destination);
  result.amplify(multiplier);
  return result;
}
